import React from "react";
import {Link} from 'react-router-dom'

function Services() {
    return(
        <div>
       <section className="section services-section" id="services">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="section-title">
                <h2>What We Do</h2>
                <p>We specialize in providing comprehensive installation, maintenance, and updating services for security camera setups. Our team of experts is highly trained in the latest technologies and techniques, ensuring that your security cameras are installed correctly and configured to provide the best possible coverage and performance. We also offer regular maintenance services to ensure that your cameras are operating at peak efficiency and can quickly identify and resolve any issues that may arise.</p>
              </div>
            </div>
          </div>
          <div className="row">
            {/* feaure box */}
            <div className="col-sm-6 col-lg-4">
            <Link to="/Contact">
              <div className="feature-box-1">
                <div style={{marginRight:180}} className="icon">
                  <i className="fa fa-cog" />
                </div>
                <div style={{marginTop:70}} className="feature-content">
                  <h5>Software Upgrade Service</h5>
                  <p> Our software upgrade service is designed to keep your security cameras running smoothly and up to date with the latest software advancements. We specialize in providing comprehensive software upgrades that ensure your cameras are equipped with the latest features and capabilities. Our team of experts is highly trained in camera software technologies and can help you identify and install the latest updates and patches. With our software upgrade service, you can rest assured that your camera installation system is always performing at its best and providing optimal security coverage. Contact us today to learn more about how we can help you upgrade your camera installation system software.
                  </p>
                </div>
              </div>
              </Link>
            </div>
            {/* / */}
            {/* feaure box */}
            <div className="col-sm-6 col-lg-4">
            <Link to="/Contact">
              <div className="feature-box-1">
                <div style={{marginRight:180}} className="icon">
                  <i className="fa fa-cog" />
                </div>
                <div style={{marginTop:70}} className="feature-content">
                  <h5>Password Reset Service</h5>
                  <p>Our password reset service is a quick and secure way to regain access to your accounts. We provide expert guidance and use the latest password reset technologies to ensure that your new password is strong and secure. Whether you've forgotten your password or suspect that your account has been compromised, our team of experts can assist you in resetting your password and regaining control of your accounts.

We understand the importance of password security and take great care to ensure that your new password is not easily guessable or hackable. We also provide guidance on how to create and manage strong passwords, so you can minimize the risk of future password-related issues.

</p>
                </div>
              </div>
              </Link>
            </div>
            {/* / */}
            {/* feaure box */}
            <div className="col-sm-6 col-lg-4">
            <Link to="/Contact">
              <div className="feature-box-1">
                <div style={{marginRight:180}} className="icon">
                  <i className="fa fa-cog" />
                </div>
                <div style={{marginTop:70}} className="feature-content">
                  <h5>Labour Installation Service</h5>
                  <p>Our labour installation service is designed to provide you with a hassle-free installation experience. We understand that installing new equipment or systems can be a daunting task, and that's why we offer a comprehensive installation service that takes care of everything for you.

Our team of experienced technicians is highly skilled in equipment installation and can provide expert guidance on the best installation practices for your particular needs. We use the latest tools and techniques to ensure that your equipment is installed correctly and configured to provide optimal performance.Contact us today to learn more about how we can assist you with your equipment installation needs.</p>
                </div>
              </div>
              </Link>
            </div>
            {/* / */}
            {/* feaure box */}
           
            {/* / */}
            {/* feaure box */}
            
            {/* / */}
            {/* feaure box */}
           
            {/* / */}
          </div>
        </div>
      </section>
        </div>
    )
}
 export default Services;