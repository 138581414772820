import logo from './logo.svg';
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Contact from './components/Contact';
import Services from './components/Services';
import Support from './components/Support';
import {BrowserRouter as Router, Routes,Route} from 'react-router-dom';
import { HashRouter } from 'react-router-dom'


function App() {
  return (
    <div className="App">
    <HashRouter>
         <Header/>  
           <Routes>
                <Route path="/" element={<Home/>} /> 
                <Route path="Services" element={<Services/>} />
                <Route path="Support" element={<Support/>} />
                <Route path="Contact" element={<Contact/>} />
           </Routes> 
        <Footer/>
    </HashRouter>
    </div>
  );
}

export default App;
