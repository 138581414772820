import React from "react";
import {Link} from 'react-router-dom'

function Footer(){
    return(
        <div>
            <footer id="footer" className="footer bg-overlay">
        <div className="footer-main">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-4 col-md-6 footer-widget footer-about">
                <h3 className="widget-title">About Us</h3>
                <p>We're dedicated to providing you with top-quality security solutions that you can rely on. Our products and services are designed to meet your unique needs and budget, and our team is always available to provide ongoing support and maintenance. Contact us today to learn more about how we can help you protect what matters most.
                  </p>
              
                <div className="footer-social">
                  <ul>
                    <li><a href="" aria-label="Facebook"><i className="fab fa-facebook-f" /></a></li>
                    <li><a href="" aria-label="Whatsapp"><i className="fab fa-whatsapp" /></a>
                    </li>
                    <li><a href="" aria-label="Instagram"><i className="fab fa-instagram" /></a></li>
                    <li><a href="" aria-label="Youtube"><i className="fab fa-youtube" /></a></li>
                  </ul>
                </div>{/* Footer social end */}
              </div>{/* Col end */}
              <div className="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0">
                <h3 className="widget-title">Working Hours</h3>
                <div className="working-hours">
                  We work 6 days a week, every day excluding major holidays. Contact us , The Services shall be carried out at the following times :<br/><br/>  
                  <br /><br /> Monday -  Saturday <span className="text-right">10:00 am - 9:00 pm </span>
                  <br /> Sunday<span style={{marginLeft:156}}>Holiday</span>
                </div>
              </div>{/* Col end */}
              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget">
                <h3 className="widget-title">Services</h3>
                <ul className="list-arrow">
                  <li><Link to="/Services">Software Upgrade Service</Link></li>
                  <li><Link to="/Services">Password Reset Service</Link></li>
                  <li><Link to="/Services">Labour Installation Service </Link></li>
  
                </ul>
              </div>{/* Col end */}
            </div>{/* Row end */}
          </div>{/* Container end */}
        </div>{/* Footer main end */}
        <div className="copyright">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="copyright-info text-center text-md-left">
                  <span>Copyright © 2023 Dahua CCTV Store -  Developed by <a href="https://www.linkedin.com/in/ali-hussain-kazmi-a62411227/">Ali Hussain Kazmi</a></span>
                </div> 
              </div>
              <div className="col-md-6">
                <div className="footer-menu text-center text-md-right">
                  <ul className="list-unstyled">
                  <li style={{marginTop:15}}><a href="/">Home</a></li>
                  <li style={{marginTop:15}}><Link to="/Services">Services</Link></li>
                  <li style={{marginTop:15}}><Link to="/Contact">Contact</Link></li>
                  </ul>
                </div>
              </div>
            </div>{/* Row end */}
            <div id="back-to-top" data-spy="affix" data-offset-top={10} className="back-to-top position-fixed">
              <button className="btn btn-primary" title="Back to Top">
                <i className="fa fa-angle-double-up" />
              </button>
            </div>
          </div>{/* Container end */}
        </div>{/* Copyright end */}
      </footer>
        </div>
    )
}
export default Footer;