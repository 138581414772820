import React from "react";

function Support(){
    return(
      <div className="container">

      <div style={{marginTop:100}} className="row">
       <div className="col-lg-6">
         <div className="section-title">
           <h2>Brand's Partners</h2>
           <p>Business kitchen gear suppliers in Pakistan. Mughal Commercial Kitchen Equipments is maybe 
             the most notable organization giving kitchen embellishments in 
             all over the world. Following are the Brands which deal with us: </p>
         </div>
       </div>
     </div>
     <div  style={{marginBottom:5}} style={{flexDirection:"row",display:"table-row"}} >
           <div className="row all-clients"> 
             <div>
               <figure className="clients-logo">
                 <a href="https://turboairinc.com/"><img style={{height:100,width:280}} loading="lazy" className="img-fluid" src="images/brands/brand1.jpg" alt="clients-logo" /></a>
               </figure>
               <a href="https://turboairinc.com/"><h4 style={{marginLeft:115,marginBottom:100}}><u>Turbo Air</u></h4></a>
             </div>
             <div >
               <figure className="clients-logo">
                 <a href="https://www.hennypenny.com/"><img style={{height:100,width:280}} loading="lazy" className="img-fluid" src="images/brands/brand2.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.hennypenny.com/"><h4 style={{marginLeft:100,marginBottom:100}}><u>Henny Penny</u></h4></a>
             </div>
             <div >
               <figure className="clients-logo">
                 <a href="https://www.middlebymarshall.com/"><img style={{height:100,width:280}} loading="lazy" className="img-fluid" src="images/brands/brand3.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.middlebymarshall.com/"><h4 style={{marginLeft:70,marginBottom:100}}><u>MiddlebyMarshall</u></h4></a>
             </div>
             <div >
               <figure className="clients-logo">
                 <a href="https://www.omnifoodequipment.com/collections/itv"><img style={{height:100,width:280}} loading="lazy" className="img-fluid" src="images/brands/brand4.jpg" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.omnifoodequipment.com/collections/itv"><h4 style={{marginLeft:150,marginBottom:100}}><u>ITV</u></h4></a>
             </div>
             <div >
               <figure className="clients-logo">
                 <a href="http://southstaroven.com/"><img loading="lazy" style={{height:100,width:280}} className="img-fluid" src="images/brands/brand5.png" alt="clients-logo" /></a>
               </figure>
               <a href="http://southstaroven.com/"><h4 style={{marginLeft:115,marginBottom:100}}><u>SouthStar</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
                 <a href="http://www.migel-ice-line.com/"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand6.jpg" alt="clients-logo" /></a>
               </figure>
               <a href="http://www.migel-ice-line.com/"><h4 style={{marginLeft:100,marginBottom:100}}><u>Migel Ice Line</u></h4></a>
             </div>
             <div >
               <figure className="clients-logo">
                 <a href="http://hengweikitchen.sell.everychina.com/aboutus.html"><img loading="lazy" className="img-fluid" style={{height:100,width:280}} src="images/brands/brand7.png" alt="clients-logo" /></a>
               </figure>
               <a href="http://hengweikitchen.sell.everychina.com/aboutus.html"><h4 style={{marginLeft:120,marginBottom:100}}><u>HENG WEI</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.laspaziale.com/index.php/en"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand8.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.laspaziale.com/index.php/en"><h4 style={{marginLeft:120,marginBottom:100}}><u>La Spaziale</u></h4></a>
             </div>
             <div >
               <figure className="clients-logo">
               <a href="http://www.gasro.com/m/eng/sub2_3.jsp"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand9.png" alt="clients-logo" /></a>
               </figure>
               <a href="http://www.gasro.com/m/eng/sub2_3.jsp"><h4 style={{marginLeft:100,marginBottom:100}}><u>Gasro(Korea)</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.krampouz.com/en/"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand10.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.krampouz.com/en/"><h4 style={{marginLeft:110,marginBottom:100}}><u>Krampouz</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.rollergrill-international.com/en/"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand11.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.rollergrill-international.com/en/"><h4 style={{marginLeft:110,marginBottom:100}}><u>Roller Grill</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.kayalarmutfak.com.tr/en/cooking-equipments"><img loading="lazy" style={{height:100,width:280}}  className="img-fluid"  src="images/brands/brand12.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.kayalarmutfak.com.tr/en/cooking-equipments"><h4 style={{marginLeft:100,marginBottom:100}}><u>Kayalar Mutfak</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.blendtec.com/"><img loading="lazy"  className="img-fluid" style={{height:100,width:280}}  src="images/brands/brand13.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.blendtec.com/"><h4 style={{marginLeft:120,marginBottom:100}}><u>Blendtec</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.taylorusa.com/pages/kitchen"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand14.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.taylorusa.com/pages/kitchen"><h4 style={{marginLeft:130,marginBottom:100}}><u>Taylor</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.cimbali.com/"><img loading="lazy" style={{height:180,width:280}} className="img-fluid"  src="images/brands/brand15.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.cimbali.com/"><h4 style={{marginLeft:130,marginBottom:100}}><u>La Cimbali</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo1">
               <a href="https://www.facebook.com/pages/category/Product-Service/ROYAL-KING-COOL-SET-4389100040192048/"><img loading="lazy" style={{height:100,width:150}} className="img-fluid"  src="images/brands/brand16.jpg" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.facebook.com/pages/category/Product-Service/ROYAL-KING-COOL-SET-4389100040192048/"><h4 style={{marginLeft:90,marginBottom:100}}><u>Royal King Cool</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.autofry.com/"><img loading="lazy" style={{height:180,width:280}} className="img-fluid"  src="images/brands/brand17.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.autofry.com/"><h4 style={{marginLeft:130,marginBottom:100}}><u>Auto Fry</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.grindmaster.com/"><img loading="lazy" style={{height:70,width:280}} className="img-fluid"  src="images/brands/brand18.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.grindmaster.com/"><h4 style={{marginLeft:100,marginBottom:100}}><u>Grind Master</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.hatcocorp.com/en"><img loading="lazy" style={{height:180,width:280}} className="img-fluid"  src="images/brands/brand19.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.hatcocorp.com/en"><h4 style={{marginLeft:130,marginBottom:100}}><u>Hatco</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.vulcanequipment.com/"><img loading="lazy" style={{height:180,width:280}} className="img-fluid"  src="images/brands/brand20.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.vulcanequipment.com/"><h4 style={{marginLeft:140,marginBottom:100}}><u>Vulcan</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.frymaster.com/"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand21.jpg" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.frymaster.com/"><h4 style={{marginLeft:100,marginBottom:100}}><u>Fry Master</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.pitco.com/"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand22.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.pitco.com/"><h4 style={{marginLeft:135,marginBottom:100}}><u>Pitco</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://pizzaovens.com/doughpro/"><img loading="lazy" style={{height:180,width:280}} className="img-fluid"  src="images/brands/brand23.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://pizzaovens.com/doughpro/"><h4 style={{marginLeft:100,marginBottom:100}}><u>DoughPro</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.alto-shaam.com/en"><img loading="lazy" style={{height:180,width:280}} className="img-fluid"  src="images/brands/brand24.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.alto-shaam.com/en"><h4 style={{marginLeft:100,marginBottom:100}}><u>Alto-Shaam</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.rational-online.com/"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand25.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.rational-online.com/"><h4 style={{marginLeft:120,marginBottom:100}}><u>Rational</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.hobartcorp.com/"><img loading="lazy" style={{height:180,width:280}} className="img-fluid"  src="images/brands/brand26.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.hobartcorp.com/"><h4 style={{marginLeft:130,marginBottom:100}}><u>Hobart</u></h4></a>
             </div> 
             <div >
               <figure className="clients-logo">
               <a href="https://www.made-in-china.com/"><img loading="lazy" style={{height:100,width:280}} className="img-fluid"  src="images/brands/brand27.png" alt="clients-logo" /></a>
               </figure>
               <a href="https://www.made-in-china.com/"><h4 style={{marginLeft:70,marginBottom:100}}><u>Chinese Equipments</u></h4></a>
             </div> 
           </div>{/* Clients row end */}
         </div>
   
    </div>
    )
}
export default Support