import React,{useState} from "react";
import axios from 'axios';


function Contact(){
  const [name,setName]=useState("");
  const [from,setFrom]=useState("");
  const [subject,setSubject]=useState("");
  const [body,setBody]=useState("");
  const [message,setMessage]=useState("Hi my name is ")
  const handleRobot = () =>{
   
  }
    return (
        <div>
        <section className="section services-section" id="services">
        <div className="container">
        
           <div className="row">
            <div className="col-lg-6">
              <div className="section-title">
                <h2>Contact Us</h2>
                <p>Dahua CCTV Store specializes in providing comprehensive security camera solutions for businesses and homeowners. With years of experience in the field, our company has earned a reputation for delivering innovative technology, efficient installations, and durable products that prioritize your safety and security.Contact us today to find out how we can help you protect what matters most.</p>
              </div>
            </div>
          </div>
          
        <div className="row" >
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
                <h2 className="section-title">Reaching our Office</h2>
                <h3 className="section-sub-title">Find Our Location</h3>
              </div>
            </div>
            {/*/ Title row end */}
            <div className="row">
              <div className="col-md-4">
                <div className="ts-service-box-bg text-center h-100">
                  <span className="ts-service-icon icon-round">
                    <i className="fas fa-map-marker-alt mr-0" />
                  </span>
                  <div className="ts-service-box-content">
                    <h4>Office Address</h4>
                    <p>H. Karim Baksh Building 65-The Mall Regal Chowk Lahore</p>
                  </div>
                </div>
              </div>{/* Col 1 end */}
              
              <div className="col-md-4">
                <div className="ts-service-box-bg text-center h-100">
                  <span className="ts-service-icon icon-round">
                    <i className="fa fa-envelope mr-0" />
                  </span>
                  <div className="ts-service-box-content">
                    <h4>Email Us</h4>
                    <p> dahuacctvstore@hotmail.com</p>
                  </div>
                </div>
              </div>{/* Col 2 end */}
              <div className="col-md-4">
                <div className="ts-service-box-bg text-center h-100">
                  <span className="ts-service-icon icon-round">
                    <i className="fa fa-phone-square mr-0" />
                  </span>
                  <div className="ts-service-box-content">
                   
                    <p>Syed Ali Turab: 03214941534</p>
                    <p>Syed Ali Hassan: 03124024846</p>
                  </div>
                </div>
              </div>{/* Col 3 end */}
            </div>{/* 1st row end */}
            <div className="gap-60" />
            
              <div ><iframe width="1100" height="350" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=H.%20Karim%20Baksh%20Building%2065-The%20Mall%20Regal%20Chowk%20Lahore+(Dahua%20CCTV%20Store)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure acres/hectares on map</a></iframe></div>
            <div className="gap-40" />
            <div className="row">
              <div className="col-md-12">
                <h3 className="column-title">We love to hear</h3>
                {/* contact form works with formspree.io  */}
                {/* contact form activation doc: https://docs.themefisher.com/constra/contact-form/ */}
                <form id="contact-form">
                  <div className="error-container" />
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Name</label>
                        <input onChange={e => setName(e.target.value)}  className="form-control form-control-name" name="name" id="name" placeholder type="text" required />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Email</label>
                        <input onChange={e => setFrom(e.target.value)}  className="form-control form-control-email" name="email" id="email" placeholder type="email" required />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Subject</label>
                        <input onChange={e => setSubject(e.target.value)}  className="form-control form-control-subject" name="subject" id="subject" placeholder required />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <textarea onChange={e => setBody(e.target.value)}  className="form-control form-control-message" name="message" id="message" placeholder rows={10} required defaultValue={""} />
                  </div>
                </form>
                <div className="text-right"><br />
                    <a href={`mailto:dahuacctvstore@hotmail.com?subject=${subject}!&body=${'Hi my name is '}${name}. \n !`}><button  className="btn btn-primary solid blank" type="submit">Send Message</button></a>
                  </div>
              </div>
            </div>{/* Content row */}
          </div>{/* Conatiner end */}
        </div>
        </div>
        </section>
        </div>
    )
}
export default Contact;