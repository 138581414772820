import React from "react";
import { Link } from 'react-router-dom'

function Header() {
  return (
    <div>



      <header id="header" className="header-one">
        <div className="bg-white">
          <div className="container">
            <div className="logo-area">
              <div className="row align-items-center">
                <div className="logo col-lg-3 text-center text-lg-left mb-3 mb-md-5 mb-lg-0">
                  <a className="d-block" href="/">
                    <img loading="lazy" src="images/logo.png" alt="Mughals" style={{ height: 100, width: 160 }} />
                  </a>
                </div>{/* logo end */}
                <div className="header-right">
                  <ul className="top-info-box">
                    <li>
                      <div className="info-box">
                        <div className="info-box-content">
                          <p className="info-box-title">Call Us</p>
                          <p className="info-box-subtitle"><a href="tel:04236299994">042-37232226</a></p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="info-box">
                        <div className="info-box-content">
                          <p className="info-box-title">Email Us</p>
                          <p className="info-box-subtitle"><a href="mailto:info@mughalsequipment.com">dahuacctvstore@hotmail.com</a></p>
                        </div>
                      </div>
                    </li>
                    <li className="last">
                      <div className="info-box last">
                        <div className="info-box-content">
                          <p className="info-box-title">Whatsapp Us</p>
                          <p className="info-box-subtitle"><a href="mailto:info@mughalsequipment.com">03214941534</a></p>
                        </div>
                      </div>
                    </li>
                    <li className="header-get-a-quote">
                      <Link className="btn btn-primary" to="/Contact">Get A Quote</Link>
                    </li>

                  </ul>{/* Ul end */}
                </div>{/* header right end */}
                <div style={{ position: 'absolute', top: 50, right: 30, }}>
                  <a className="d-block" href="/">
                    <img loading="lazy" src="images/logo2.png" alt="Mughals" style={{ height: 60, width: 180 }} />
                  </a>
                </div>{/* logo end */}
              </div>{/* logo area end */}
            </div>{/* Row end */}
          </div>{/* Container end */}
        </div>
        <div className="site-navigation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div id="navbar-collapse" className="collapse navbar-collapse">
                    <ul className="nav navbar-nav mr-auto">
                      <li className="nav-item"><a className="nav-link" href="/">Home</a></li>
                      <li className="nav-item dropdown">
                        <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Products<i className="fa fa-angle-down" /></a>
                        <ul className="dropdown-menu" role="menu">
                          <li className="dropdown-submenu">
                            <a href="#!" className="dropdown-toggle" data-toggle="dropdown">P0E Switches Dahua</a>
                            <ul className="dropdown-menu" style={{ columnCount: 2 }}>
                              <li style={{ width: 160 }}><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3006-4ET-36.pdf">DH-PFS3006-4ET-36</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3006-4ET-60.pdf">DH-PFS3006-4ET-60</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3006-4GT-60.pdf">DH-PFS3006-4GT-60</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3010-8ET-65.pdf">DH-PFS3010-8ET-65</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3010-8ET-96.pdf">DH-PFS3010-8ET-96</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3010-8GT-96.pdf">DH-PFS3010-8GT-96</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3218-16ET-135.pdf">DH-PFS3218-16ET-135</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3220-16GT-190.pdf">DH-PFS3220-16GT-190</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3220-16GT-240.pdf">DH-PFS3220-16GT-240</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS3226-24ET-240.pdf">DH-PFS3226-24ET-240</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS4207-4GT-DP.pdf">DH-PFS4207-4GT-DP</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS4210-8GT-DP.pdf">DH-PFS4210-8GT-DP</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS4218-16GT-190.pdf">DH-PFS4218-16GT-190</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS4218-16GT-240.pdf">DH-PFS4218-16GT-240</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS4226-24GT-240.pdf">DH-PFS4226-24GT-240</a></li>
                              <li><a href="/pdfs/P0E SWITCHES DAHUA/DH-PFS4226-24GT-360.pdf">DH-PFS4226-24GT-360</a></li>
                            </ul>

                          </li>

                          <li className="dropdown-submenu">
                            <a href="#!" className="dropdown-toggle" data-toggle="dropdown">Network Switches</a>
                            <ul className="dropdown-menu" >
                              <li><a href="/pdfs/NETWORK SWITCHES/DH-PFS3005-5ET.pdf">DH-PFS3005-5ET</a></li>
                              <li><a href="/pdfs/NETWORK SWITCHES/DH-PFS3005-5GT.pdf">DH-PFS3005-5GT</a></li>
                              <li><a href="/pdfs/NETWORK SWITCHES/DH-PFS3008-8ET.pdf">DH-PFS3008-8ET</a></li>
                              <li><a href="/pdfs/NETWORK SWITCHES/DH-PFS3008-8GT.pdf">DH-PFS3008-8GT</a></li>
                              <li><a href="/pdfs/NETWORK SWITCHES/DH-PFS3016-16GT.pdf">DH-PFS3016-16GT</a></li>
                              <li><a href="/pdfs/NETWORK SWITCHES/DH-PFS3024-24GT.pdf">DH-PFS3024-24GT</a></li>

                            </ul>
                          </li>

                          <li className="dropdown-submenu">
                            <a href="#!" className="dropdown-toggle" data-toggle="dropdown">Dahua NVR</a>
                            <ul className="dropdown-menu" style={{ columnCount: 4 }}>
                              <li style={{ width: 210 }}><a href="/pdfs/DAHUA NVR/DHI-NVR1104HS-S3-H.pdf">DHI-NVR1104HS-S3-H</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR1108HS-S3-H.pdf">DHI-NVR1108HS-S3-H</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4232-EI.pdf">DHI-NVR4232-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR2104-I2.pdf">DHI-NVR2104-I2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4416, 4432-4KS2.pdf">DHI-NVR4416, 4432-4KS2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR2104HS-I2.pdf">DHI-NVR2104HS-I2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4416-EI.pdf">DHI-NVR4416-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR2108-I2.pdf">DHI-NVR2108-I2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4432-EI.pdf">DHI-NVR4432-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR2108HS-I2.pdf">DHI-NVR2108HS-I2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4816, 4832-4KS2.pdf">DHI-NVR4816, 4832-4KS2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR2116-I2.pdf">DHI-NVR2116-I2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4816-EI.pdf">DHI-NVR4816-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR2116HS-I2.pdf">DHI-NVR2116HS-I2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4832-EI.pdf">DHI-NVR4832-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4104-EI.pdf">DHI-NVR4104-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5208, 5216, 5232-4KS2.pdf">DHI-NVR5208, 5216, 5232-4KS2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4104HS-4KS2-L.pdf">DHI-NVR4104HS-4KS2-L</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5208-EI.pdf">DHI-NVR5208-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4108-EI.pdf">DHI-NVR4108-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5216-EI.pdf">DHI-NVR5216-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4108HS-4KS2-L.pdf">DHI-NVR4108HS-4KS2-L</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5232-EI.pdf">DHI-NVR5232-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4116-EI.pdf">DHI-NVR4116-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5416, 5432, 5464-4KS2.pdf">DHI-NVR5416, 5432, 5464-4KS2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4116HS-4KS2-L.pdf">DHI-NVR4116HS-4KS2-L</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5416-EI.pdf">DHI-NVR5416-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5432-EI.pdf">DHI-NVR5432-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5464-EI.pdf">DHI-NVR5464-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4204-4KS2-L.pdf">DHI-NVR4204-4KS2-L</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5816, 5832, 5864-4KS2.pdf">DHI-NVR5816, 5832, 5864-4KS2</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4204-EI.pdf">DHI-NVR4204-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5816-EI.pdf">DHI-NVR5816-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4208-4KS2-L.pdf">DHI-NVR4208-4KS2-L</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5832-EI.pdf">DHI-NVR5832-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR4208-EI.pdf">DHI-NVR4208-EI</a></li>
                              <li><a href="/pdfs/DAHUA NVR/DHI-NVR5864-EI.pdf">DHI-NVR5864-EI</a></li>

                            </ul>
                          </li>

                          <li className="dropdown-submenu">
                            <a href="#!" className="dropdown-toggle" data-toggle="dropdown">Dahua XVR</a>
                            <ul className="dropdown-menu" style={{ columnCount: 2 }}>
                              <li style={{ width: 160 }}><a href="/pdfs/DAHUA XVR/DH-XVR1B04-I.pdf">DH-XVR1B04-I</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR4216AN-I.pdf">DH-XVR4216AN-I</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR5116H-4KL-I3.pdf">DH-XVR5116H-4KL-I3</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR1B04H-I.pdf">DH-XVR1B04H-I</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR4232AN-I.pdf">DH-XVR4232AN-I</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR5116HS-I3.pdf">DH-XVR5116HS-I3</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR1B08-I.pdf">DH-XVR1B08-I</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR5104HS-4KL-I3.pdf">DH-XVR5104HS-4KL-I3</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR5216AN-I3.pdf">DH-XVR5216AN-I3</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR1B08H-I.pdf">DH-XVR1B08H-I</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR5104HS-I3.pdf">DH-XVR5104HS-I3</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR5232AN-I3.pdf">DH-XVR5232AN-I3</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR1B16-I.pdf">DH-XVR1B16-I</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR5108HS-4KL-I3.pdf">DH-XVR5108HS-4KL-I3</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR1B16H-I.pdf">DH-XVR1B16H-I</a></li>
                              <li><a href="/pdfs/DAHUA XVR/DH-XVR5108HS-I3.pdf">DH-XVR5108HS-I3</a></li>
                            </ul>
                          </li>

                          <li className="dropdown-submenu">
                            <a href="#!" className="dropdown-toggle" data-toggle="dropdown"> Dahua IP Cameras</a>
                            <ul className="dropdown-menu" style={{ columnCount: 3 }}>
                              <li style={{ width: 210 }}><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW1230T1-A-S5.pdf">DH-IPC-HDW1230T1-A-S5</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2231SP-S-S2.pdf">DH-IPC-HFW2231SP-S-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW1230T1-S5.pdf">DH-IPC-HDW1230T1-S5</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2231TP-AS-S2.pdf">DH-IPC-HFW2231TP-AS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW1239T1-LED-S5.pdf">DH-IPC-HDW1239T1-LED-S5</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2231TP-ZS-S2.pdf">DH-IPC-HFW2231TP-ZS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW1431T1-A-S4.pdf">DH-IPC-HDW1431T1-A-S4</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2431SP-S-S2.pdf">DH-IPC-HFW2431SP-S-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW1431T1-S4.pdf">DH-IPC-HDW1431T1-S4</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2431TP-AS-S2.pdf">DH-IPC-HFW2431TP-AS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW1439T1-LED-S4.pdf">DH-IPC-HDW1439T1-LED-S4</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2431TP-ZS-S2.pdf">DH-IPC-HFW2431TP-ZS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW2231TP-AS-S2.pdf">DH-IPC-HDW2231TP-AS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2531SP-S-S2.pdf">DH-IPC-HFW2531SP-S-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW2431TP-AS-S2.pdf">DH-IPC-HDW2431TP-AS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2531TP-AS-S2.pdf">DH-IPC-HFW2531TP-AS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW2531TP-AS-S2.pdf">DH-IPC-HDW2531TP-AS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2531TP-ZS-S2.pdf">DH-IPC-HFW2531TP-ZS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HDW2831TP-AS-S2.pdf">DH-IPC-HDW2831TP-AS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2831SP-S-S2.pdf">DH-IPC-HFW2831SP-S-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW1230S1-A-S5.pdf">DH-IPC-HFW1230S1-A-S5</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2831TP-AS-S2.pdf">DH-IPC-HFW2831TP-AS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW1230S1-S5.pdf">DH-IPC-HFW1230S1-S5</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW2831TP-ZS-S2.pdf">DH-IPC-HFW2831TP-ZS-S2</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW1239S1-LED-S5.pdf">DH-IPC-HFW1239S1-LED-S5</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-SD49225XA-HNR-S3.pdf">DH-SD49225XA-HNR-S3</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW1431S1-A-S4.pdf">DH-IPC-HFW1431S1-A-S4</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-SD49425XB-HNR-S3.pdf">DH-SD49425XB-HNR-S3</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW1431S1P-S4.pdf">DH-IPC-HFW1431S1P-S4</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-SD6CE445XA-HNR.pdf">DH-SD6CE445XA-HNR</a></li>
                              <li><a href="/pdfs/DAHUA IP CAMERAS/IP 2MP CAMERA/DH-IPC-HFW1439S1-LED-S4.pdf">DH-IPC-HFW1439S1-LED-S4</a></li>
                            </ul>
                          </li>

                          <li className="dropdown-submenu">
                            <a href="#!" className="dropdown-toggle" data-toggle="dropdown">HDCIV Cameras 2MP</a>
                            <ul className="dropdown-menu" style={{ columnCount: 2 }}>
                              <li style={{ width: 250 }}><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-B1A21P CAMERA.pdf">HAC-B1A21P CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HFW1200CP-A CAMERA.pdf">HAC-HFW1200CP-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HDW1200EMP-A CAMERA.pdf">HAC-HDW1200EMP-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HFW1200TLP CAMERA.pdf">HAC-HFW1200TLP CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HDW1200TLP-A CAMERA.pdf">HAC-HDW1200TLP-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HFW1200TLP-A CAMERA.pdf">HAC-HFW1200TLP-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HDW1200TMQP CAMERA.pdf">HAC-HDW1200TMQP CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HFW1209CP-LED CAMERA.pdf">HAC-HFW1209CP-LED CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HDW1200TMQP-A CAMERA.pdf">HAC-HDW1200TMQP-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HFW1239TMP-IL-A CAMERA.pdf">HAC-HFW1239TMP-IL-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HDW1200TRQP CAMERA.pdf">HAC-HDW1200TRQP CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-T1A21P CAMERA.pdf">HAC-T1A21P CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HDW1200TRQP-A CAMERA.pdf">HAC-HDW1200TRQP-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HFW1200RP-Z-IRE6 CAMERA.pdf">HFW1200RP-Z-IRE6 CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HDW1209TLQP-LED CAMERA.pdf">HAC-HDW1209TLQP-LED CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HFW1200RP-Z-IRE6-A CAMERA.pdf">HFW1200RP-Z-IRE6-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 2MP/HAC-HFW1200CP CAMERA.pdf">HAC-HFW1200CP CAMERA</a></li>
                            </ul>
                          </li>

                          <li className="dropdown-submenu">
                            <a href="#!" className="dropdown-toggle" data-toggle="dropdown">HDCIV Cameras 5MP</a>
                            <ul className="dropdown-menu" style={{ columnCount: 2 }}>
                              <li style={{ width: 250 }}><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-B1A51P CAMERA.pdf">HAC-B1A51P CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HFW1500RP-Z-IRE6 CAMERA.pdf">HAC-HFW1500RP-Z-IRE6 CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HDW1500TLMQP CAMERA.pdf">HAC-HDW1500TLMQP CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HFW1500RP-Z-IRE6-A CAMERA.pdf">HAC-HFW1500RP-Z-IRE6-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HDW1500TLMQP-A CAMERA.pdf">HAC-HDW1500TLMQP-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HFW1500TLP CAMERA.pdf">HAC-HFW1500TLP CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HDW1500TRQP CAMERA.pdf">HAC-HDW1500TRQP CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HFW1500TLP-A CAMERA.pdf">HAC-HFW1500TLP-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HDW1500TRQP-A CAMERA.pdf">HAC-HDW1500TRQP-A CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HFW1509TLMP-A-LED CAMERA.pdf">HAC-HFW1509TLMP-A-LED CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HDW1509TLQP-A-LED CAMERA.pdf">HAC-HDW1509TLQP-A-LED CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HFW1509TLMP-LED CAMERA.pdf">HAC-HFW1509TLMP-LED CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-HDW1509TLQP-LED CAMERA.pdf">HAC-HDW1509TLQP-LED CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-ME1509THP-A-PV CAMERA.pdf">HAC-ME1509THP-A-PV CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-ME1509TQP-A-PV CAMERA.pdf">HAC-ME1509TQP-A-PV CAMERA</a></li>
                              <li><a href="/pdfs/HDCVI CAMERAS/HDCVI CAMERA 5MP/HAC-T1A51P CAMERA.pdf">HAC-T1A51P CAMERA</a></li>

                            </ul>
                          </li>

                          <li className="dropdown-submenu">
                            <a href="#!" className="dropdown-toggle" data-toggle="dropdown"> CAT6 Cables Dahua</a>
                            <ul className="dropdown-menu">
                              <li><a href="/pdfs/CAT6 CABLE DAHUA/DH-PFM920I-6U-C.pdf">DH-PFM920I-6U-C</a></li>
                              <li><a href="/pdfs/CAT6 CABLE DAHUA/DH-PFM920I-6U-U.pdf">DH-PFM920I-6U-U</a></li>
                              <li><a href="/pdfs/CAT6 CABLE DAHUA/DH-PFM920I-6UN-C.pdf">DH-PFM920I-6UN-C</a></li>
                            </ul>
                          </li>

                        </ul>
                      </li>
                      <li style={{ marginTop: 15 }}><Link to="/Services">Services</Link></li>

                      <li style={{ marginTop: 15 }}><Link to="/Contact">Contact</Link></li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/*/ Col end */}
            </div>
            {/*/ Row end */}
          </div>
          {/*/ Container end */}
        </div>
        {/*/ Navigation end */}
      </header>
    </div>
  )
}

export default Header;