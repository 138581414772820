import React, {useState} from "react";
import {Link} from 'react-router-dom'

function Home(){
    
    return(
       <div>
         <div className="banner-carousel banner-carousel-1 mb-0">
          <div className="banner-carousel-item" style={{backgroundImage: 'url(images/slider-main/camBanner4.jpg)'}}>
            
          </div>
          <div className="banner-carousel-item" style={{backgroundImage: 'url(images/slider-main/camBanner3.jpg)'}}>
            
          </div>
          <div className="banner-carousel-item" style={{backgroundImage: 'url(images/slider-main/camBanner1.jpg)'}}>
           
          </div>
        </div>
        <section className="call-to-action-box no-padding">
          <div className="container">
            <div className="action-style-box">
              <div className="row align-items-center">
                <div className="col-md-8 text-center text-md-left">
                  <div className="call-to-action-text">
                    <h3 className="action-title">We understand your needs</h3>
                  </div>
                </div>{/* Col end */}
                <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                  <div className="call-to-action-btn">
                    <Link className="btn btn-dark" to="/Contact">Request Quote</Link>
                  </div>
                </div>{/* col end */}
              </div>{/* row end */}
            </div>{/* Action style box */}
          </div>{/* Container end */}
        </section>{/* Action end */}
        <section id="ts-features" className="ts-features">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="ts-intro">
                  <h2 className="into-title">About Us</h2>
                  <h3 className="into-sub-title">We provide Security Equipments</h3>
                  <p>We understand that security is a top priority for any business or homeowner. That's why we offer a comprehensive range of products and services to provide you with the peace of mind that you deserve.
Our product line includes a variety of security cameras, from indoor to outdoor, wired to wireless, and high-resolution options. We carefully select our products to ensure that they meet the highest industry standards for reliability, durability, and performance.
But our commitment to security doesn't stop at installation. We also provide ongoing maintenance and support services to ensure that your security system is always up and running. From troubleshooting to software updates, our team is always available to help you with any issues that you may encounter.</p>
                </div>{/* Intro box end */}
                <div className="gap-20" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="ts-service-box">
                      <span className="ts-service-icon">
                        <i className="fas fa-trophy" />
                      </span>
                      <div className="ts-service-box-content">
                        <h3 className="service-box-title">We've Repution for Excellence</h3>
                      </div>
                    </div>{/* Service 1 end */}
                  </div>{/* col end */}
                  <div className="col-md-6">
                    <div className="ts-service-box">
                      <span className="ts-service-icon">
                        <i className="fas fa-sliders-h" />
                      </span>
                      <div className="ts-service-box-content">
                        <h3 className="service-box-title">We Build Partnerships</h3>
                      </div>
                    </div>{/* Service 2 end */}
                  </div>{/* col end */}
                </div>{/* Content row 1 end */}
                <div className="row">
                  <div className="col-md-6">
                    <div className="ts-service-box">
                      <span className="ts-service-icon">
                        <i className="fas fa-thumbs-up" />
                      </span>
                      <div className="ts-service-box-content">
                        <h3 className="service-box-title">Guided by Commitment</h3>
                      </div>
                    </div>{/* Service 1 end */}
                  </div>{/* col end */}
                  <div className="col-md-6">
                    <div className="ts-service-box">
                      <span className="ts-service-icon">
                        <i className="fas fa-users" />
                      </span>
                      <div className="ts-service-box-content">
                        <h3 className="service-box-title">A Team of Professionals</h3>
                      </div>
                    </div>{/* Service 2 end */}
                  </div>{/* col end */}
                </div>{/* Content row 1 end */}
              </div>{/* Col end */}
              <div className="col-lg-6 mt-4 mt-lg-0">
                <h3 className="into-sub-title1">Our Values</h3>
                <p style={{marginTop:45}}>Our goal is to be the industry leader through great products and services,
                     great people and great communities, and we actively support values that 
                     bring us closer to achieving these goals.</p>
                <div className="accordion accordion-group" id="our-values-accordion">
                  <div className="card">
                    <div className="card-header p-0 bg-transparent" id="headingOne">
                      <h2 className="mb-0">
                        <button className="btn btn-block text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Innovation
                        </button>
                      </h2>
                    </div>
                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#our-values-accordion">
                      <div className="card-body">
                      We encourage creativity that adds value throughout for our customers, employees and shareholders.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header p-0 bg-transparent" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Customer Service
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#our-values-accordion">
                      <div className="card-body">
                      To ensure we surpass expectations and become the supplier and servicer of choice.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header p-0 bg-transparent" id="headingThree">
                      <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        Teamwork and Accountability
                        </button>
                      </h2>
                    </div>
                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#our-values-accordion">
                      <div className="card-body">
                        To each other, our organization and our customers.
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header p-0 bg-transparent" id="headingTwo">
                      <h2 className="mb-0">
                        <button className="btn btn-block text-left collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        Diversity
                        </button>
                      </h2>
                    </div>
                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#our-values-accordion">
                      <div className="card-body">
                      With a commitment to equal opportunity and an employee base as diverse as our customers and product offerings.
                      </div>
                    </div>
                  </div>
                </div>
                {/*/ Accordion end */}
              </div>{/* Col end */}
            </div>{/* Row end */}
          </div>{/* Container end */}
        </section>{/* Feature are end */}
        <section id="facts" className="facts-area dark-bg">
          <div  className="container">
            <div style={{marginLeft:'20%'}} className="facts-wrapper">
              <div className="row">
                <div className="col-md-3 col-sm-6 ts-facts">
                  <div className="ts-facts-img">
                    <img loading="lazy" src="images/icon-image/service-icon2.png" alt="facts-img" />
                  </div>
                  <div className="ts-facts-content">
                    <h2 className="ts-facts-num"><span className="counterUp" data-count={1000}>0</span></h2>
                    <h3 className="ts-facts-title">Total Products</h3>
                  </div>
                </div>{/* Col end */}
                <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-sm-0">
                  <div className="ts-facts-img">
                    <img loading="lazy" src="images/icon-image/fact2.png" alt="facts-img" />
                  </div>
                  <div className="ts-facts-content">
                    <h2 className="ts-facts-num"><span className="counterUp" data-count={50}>0</span></h2>
                    <h3 className="ts-facts-title">Staff Members</h3>
                  </div>
                </div>{/* Col end */}
                <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0">
                  <div className="ts-facts-img">
                    <img loading="lazy" src="images/icon-image/fact3.png" alt="facts-img" />
                  </div>
                  <div className="ts-facts-content">
                    <h2 className="ts-facts-num"><span className="counterUp" data-count={20}>0</span></h2>
                    <h3 className="ts-facts-title">Years of Work</h3>
                  </div>
                </div>{/* Col end */}
                
              </div> {/* Facts end */}
            </div>
            {/*/ Content row end */}
          </div>
          {/*/ Container end */}
        </section>{/* Facts end */}
        <section id="ts-service-area" className="ts-service-area pb-0">
          <div className="container">
            <div className="row text-center">
              <div className="col-12">
               
                <h2 className="section-sub-title">Why Hire Us?</h2>
              </div>
            </div>
            {/*/ Title row end */}
            <div>
             
              <p style={{fontWeight:200}}>
              Some of the primary reasons why The Dahua CCTV Store should be your
               first choice for Security equipment installation and repairs include:<br/>
              </p>
              <p style={{fontWeight:800}}>
              - Fastest response time in the industry<br/><br/>
              - Customer service excellence<br/><br/>
              - Quick and reliable solutions for installations and repairs<br/><br/>
              - Dedication to manage and expand the lifecycle of your Security System<br/><br/>
              - Invaluable insight into effective Security installations<br/><br/>
              - Follow-on maintenance and repairs if required<br/><br/>
              - Highly qualified and trained staff<br/><br/>
              - Cost competitive rates<br/><br/>
              - And much more!<br/><br/>
              </p>
           </div>
           <div className="general-btn text-center mt-4">
              <Link className="btn btn-primary" to="/Services">Go To Our Services</Link>
            </div>
          </div>
          {/*/ Container end */}
        </section>{/* Service end */}
       

        <section style={{marginTop:30, width:'100%'}} className="subscribe no-padding">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="subscribe-call-to-acton">
                  <h3>Can We Help?</h3>
                  <h4>(042) 3629-9994</h4>
                </div>
              </div>{/* Col end */}
              <div className="col-md-8">
                <div className="ts-newsletter row align-items-center">
                  <div className="col-md-5 newsletter-introtext">
                    <h4 className="text-white mb-0">Newsletter Sign-up</h4>
                    <p className="text-white">Latest updates and news</p>
                  </div>
                  <div className="col-md-7 newsletter-form">
                  <div style={{marginLeft:150}}>
                     <a className="btn btn-primary" href="mailto:info@mughalsequipment.com">Click For Email Us</a>
                  </div>
                  </div>
                </div>{/* Newsletter end */}
              </div>{/* Col end */}
            </div>{/* Content row end */}
          </div>
          {/*/ Container end */}
        </section>
        {/*/ subscribe end */}
       </div>
    )
}
export default Home;